export default {
    get UserToken() {
        return localStorage.getItem('token')
    },

    set UserToken(value) {
        localStorage.setItem('token', value)
    },

    get userID() {
        return localStorage.getItem('userID')
    },
    set userID(value) {
        localStorage.setItem('userID', value)
    },

   
    get permissionList() {
        return localStorage.getItem('permissionList')
    },
    set permissionList(value) {
        localStorage.setItem('permissionList', value)
    },

}