import request from '@/utils/request'
// 帮助中心
// 帮助文档列表
export function webHelpListData(data) {
    return request({
        url: '/webHelp/findPage',
        method: 'post',
        data
    })
}
// 分类
// 分类列表
export function webHelpCategoryListData(data) {
    return request({
        url: '/webHelpCategory/findPage',
        method: 'post',
        data
    })
}
// 分类
// 分类列表
export function webHelpCategoryFindList(data) {
    return request({
        url: '/webHelpCategory/list',
        method: 'post',
        data
    })
}
// 分类详情
export function webHelpCategoryDetail(data) {
    return request({
        url: 'webHelpCategory/detail',
        method: 'post',
        data
    })
}
// 删除分类
export function webHelpCategoryDeleteById(data) {
    return request({
        url: 'webHelpCategory/deleteById',
        method: 'post',
        data
    })
}

// 分类添加
export function webHelpCategoryInsert(data) {
    return request({
        url: '/webHelpCategory/insert',
        method: 'post',
        data
    })
}

// 更新分类
export function updateClassStateById(data) {
    return request({
        url: '/webHelpCategory/updateStateById',
        method: 'post',
        data
    })
}

// 添加帮助文档
export function addInsert(data) {
    return request({
        url: '/webHelp/insert',
        method: 'post',
        data
    })
}

// 添加帮助文档详情
export function webHelpDetail(data) {
    return request({
        url: '/webHelp/detail',
        method: 'post',
        data
    })
}

// 更新帮助文档详情
export function updateStateById(data) {
    return request({
        url: '/webHelp/updateStateById',
        method: 'post',
        data
    })
}

// 删除详情
export function deleteByIdDetail(data) {
    return request({
        url: '/webHelp/deleteById',
        method: 'post',
        data
    })
}