import Cookies from 'js-cookie'
import store from "@/store"
const TokenKey = 'Admin-Token'
const TokenID = 'Admin-Token-ID'

export function getToken() {
  var tokens = ''
  if (store.state.getToken) {
    tokens = store.state.getToken
  } else {
    tokens = Cookies.get(TokenKey)
  }
  return tokens
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 获取登录tokenID
export function getTokenID() {

  var tokenID = ''
  if (store.state.getTokenID) {
    tokenID = store.state.getTokenID
  } else {
    tokenID = Cookies.get(TokenID)
  }

  return tokenID
}

// 存储登录tokenID
export function setTokenID(tokenID) {
  return Cookies.set(TokenID, tokenID)
}

// 移除tokenID
export function removeTokenID() {
  return Cookies.remove(TokenID)
}

// 缓存个人信息
export function addLocalMessage(dataMessage) {
  if (dataMessage) {
    localStorage.setItem('myMessage', JSON.stringify(dataMessage))
  }

}
// 获取个人信息
export function getMessage() {
  let getStorage = localStorage.getItem('myMessage')
  var parame = JSON.parse(getStorage ? getStorage : '{}')
  return parame
}

// 移除个人信息
export function removeMessage() {
  localStorage.removeItem('myMessage')

}