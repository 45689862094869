
import { permissions } from "@/api/login";
import router, { DynamicRoutes } from "@/router/index"
import dynamicRouter_bendi from "@/router/dynamic-router"
import { recursionRouter, setDefaultRoute } from "@/utils/recursion-router"
import store from "@/store"
import { getToken, removeToken, removeMessage } from '@/utils/auth'

export default {
    namespaced: true,
    state: {
        permissionList: null,
        sidebarMenu: [],// 导航菜单
        currentMenu: '' // 高亮
    },
    getters: {},
    mutations: {
        SET_PERMISSION(state, routes) {
            state.permissionList = routes;
        },
        CLEAR_PERMSSION(state) {
            state.permissionList = null;
        },
        SET_MENU(state, menu) {
            state.sidebarMenu = menu;
        },
        CLEAR_MENU(state) {
            state.sidebarMenu = null
        }
    },
    // 异步访问
    actions: {
        async FETCH_PERMISSION({ commit, state }) {
            // 获取本地路由
            let MainContainer = DynamicRoutes.find(v => v.path === "");
            let children = MainContainer.children;
            if (children.length > 1) {
                children.splice(1);
                console.log(children)
            }
            // 网络路由
            let res = await permissions({ id: store.state.userID });
            let menuList = res.data.menuList
            // 筛选
            let routes = recursionRouter(menuList, dynamicRouter_bendi);

            children.push(...routes)

            // 生成菜单
            commit("SET_MENU", children);

            // 设置默认路由
            setDefaultRoute([MainContainer]);
            // 初始化路由
            let initialRoutes = router.options.routes;

            // 存储权限
            let permissionsList = JSON.stringify(res.data.permissionsList)
            store.commit('PERMISSION_LIST', permissionsList)

            router.addRoutes(DynamicRoutes);
            commit("SET_PERMISSION", [...initialRoutes, ...DynamicRoutes])

        },
        CLEAR_RRR({ commit, state }) {
            commit("CLEAR_PERMSSION")
            commit("CLEAR_MENU")


        }
    }
}