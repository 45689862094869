//引入
import { JSEncrypt } from 'jsencrypt'
/**
 * 加密
 * @param {String}  需要加密的参数
 */
export function rsaEncrypt(param) {
  // 后台给的公钥
  let publicKey = decodeURIComponent('MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnZbOiC8RfCxbG1O9v92JoKJvhEb9qoFPKHf5z%2FFx%2FYuBrXoS0jw9ftOn9a4NM%2F2RkEaHAvX%2Ftwqsx4jtBC267hknu%2Bq%2BpvMwDSTFaCUO3Kj10fZ9nUjxTZcXSDJg14iGnSgNj5V7QTh4Me%2FIOltq17yuPOm7NqZVa6%2FwRWsaAyG0fAlrViakmlc5%2BuqlT5WRndPTOPKiMr9v%2Bzgy20EAyQTOj2zDtFU6d3pmNMCsXT2sGzbd3DbmKBcqecYURc9kg%2Bh1OUzOanVGk7xW7EkP0uvt%2F0hM%2FpzOre1UDv%2Bt57ijzMn2CWuYqn8Ng1dawYqwDltA8G9iM721pIPQ25vDuwIDAQAB')
  let encryptor = new JSEncrypt() // 新建JSEncrypt对象
  encryptor.setPublicKey(publicKey) // 设置公钥
  let passwordEncryp = encryptor.encrypt(param) // 对密码进行加密
  return passwordEncryp
}

// 解密
export function decrypt(msg) {
  const privateKey = ''
  let decrypt = new JSEncrypt()
  decrypt.setPrivateKey(privateKey)
  var decryptMsg = decrypt.decrypt(msg)
  return decryptMsg
}
