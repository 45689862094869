import Vue from "vue"
import VueI18n from 'vue-i18n';

//引入自定义语言配置  
import zh from './lang/zh'
import en from './lang/en'
// import de from './lang/de'
// import fr from './lang/fr'
// import pt from './lang/pt'
// import ru from './lang/ru'
// import tr from './lang/tr'
// import es from './lang/es'


//引入UI框架语言配置---elementui
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
// import esLocale from 'element-ui/lib/locale/lang/es' // 西班牙语
// import frLocale from 'element-ui/lib/locale/lang/fr' // 法语
// import deLocale from 'element-ui/lib/locale/lang/de' // 德语
// import ruLocale from 'element-ui/lib/locale/lang/ru-RU' // 俄语
// import ptLocale from 'element-ui/lib/locale/lang/pt' // 葡萄牙语
// import trLocale from 'element-ui/lib/locale/lang/tr-TR' // 土耳其语


ElementLocale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换
Vue.use(VueI18n); // 全局注册国际化包

let i18n = new VueI18n({
  // locale: localStorage.getItem('language') || 'en',
  locale:  'en',
  messages: {
    // 中文
    'zh': {
      ...zh,
      ...zhLocale
    },
    // 英文
    'en': {
      ...en,
      ...enLocale
    }
    // // 西班牙语言
    // 'es': {
    //   ...es,
    //   ...esLocale
    // },
    // // 法语
    // 'fr': {
    //   ...fr,
    //   ...frLocale
    // },
    // // 德语
    // 'de': {
    //   ...de,
    //   ...deLocale
    // },
    // // 俄语
    // 'ru': {
    //   ...ru,
    //   ...ruLocale
    // },
    // // 葡萄牙语
    // 'pt': {
    //   ...pt,
    //   ...ptLocale
    // },
    // // 土耳其语
    // 'tr': {
    //   ...tr,
    //   ...trLocale
    // }
  },
  silentTranslationWarn: true, //解决vue-i18n黄色警告"value of key 'xxx' is not a string"和"cannot translate the value of keypath 'xxx'.use the value of keypath as default",可忽略
  globalInjection: true, // 全局注入
  fallbackLocale: 'en', // 指定的locale没有找到对应的资源或当前语种不存在时，默认设置当前语种为中文

})

export default i18n;