
const dynamicRoutes = [


    {
        path: '/customer', // 客户管理 
        name: 'customer',
        component: () => import('../views/home/customer/index.vue'),
        meta: { name: 'customer', icon: 'el-icon-s-custom', showName: 'home.homeLeft.customer', showHiden: true, index: '/customer' }
    },
    {
        path: '/customerDetail', // 客户管理-详情
        name: 'customerDetail',
        component: () => import('../views/home/customer/detail.vue'),
        meta: { name: 'customer', showHiden: false, index: '/customer' } // 表示该路由需要身份验证

    },
    {
        path: '/exhibition', // 展会 
        name: 'exhibition',
        component: () => import('../views/home/exhibition/index.vue'),
        meta: { name: 'exhibition', icon: 'el-icon-s-cooperation', showName: 'home.homeLeft.exhibition', showHiden: true, index: '/exhibition' }
    },
    {
        path: '/exhibitionAdd', // 展会-创建
        name: 'exhibitionAdd',
        component: () => import('../views/home/exhibition/add.vue'),
        meta: { name: 'exhibition', showHiden: false, index: '/exhibition' } // 表示该路由需要身份验证
    },
    {
        path: '/exhibitionDetail', // 展会-详情
        name: 'exhibitionDetail',
        component: () => import('../views/home/exhibition/detail.vue'),
        meta: { name: 'exhibition', showHiden: false, index: '/exhibition' } // 表示该路由需要身份验证
    },
    {
        path: '/news', // 新闻
        name: 'news',
        component: () => import('../views/home/news/index.vue'),
        meta: { name: 'news', icon: 'el-icon-message-solid', showName: 'home.homeLeft.news', showHiden: true, index: '/news' } // 表示该路由需要身份验证
    },
    {
        path: '/newsAdd', // 新闻-添加
        name: 'newsAdd',
        component: () => import('../views/home/news/add.vue'),
        meta: { name: 'news', showHiden: false, index: '/news' } // 表示该路由需要身份验证
    },
    {
        path: '/newsDetail', // 新闻-详情
        name: 'newsDetail',
        component: () => import('../views/home/news/detail.vue'),
        meta: { name: 'news', showHiden: false, index: '/news' } // 表示该路由需要身份验证
    },
    {
        path: '/recovery', // 回收信息管理 
        name: 'recovery',
        component: () => import('../views/home/recovery/index.vue'),
        meta: { name: 'recovery', icon: 'el-icon-s-cooperation', showName: 'home.homeLeft.recovery', showHiden: true, index: '/recovery' } // 表示该路由需要身份验证
    },
    {
        path: '/recoveryDetail', // 回收信息管理详情
        name: 'recoveryDetail',
        component: () => import('../views/home/recovery/detail.vue'),
        meta: { name: 'recovery', showHiden: false, index: '/recovery' } // 表示该路由需要身份验证
    },
    {
        path: '/saleproduct', // 处置信息管理 
        name: 'saleproduct',
        component: () => import('../views/home/saleproduct/index.vue'),
        meta: { name: 'sale:product', icon: 'el-icon-menu', showName: 'home.homeLeft.saleProduct', showHiden: true, index: '/saleproduct' } // 表示该路由需要身份验证
    },
    {
        path: '/saleproductDetail', // 处置信息管理 -详情
        name: 'saleproductDetail',
        component: () => import('../views/home/saleproduct/detail.vue'),
        meta: { name: 'sale:product', showHiden: false, index: '/saleproduct' } // 表示该路由需要身份验证

    },
    {
        path: '/helpcenter', // 帮助中心 
        name: 'helpcenter',
        component: () => import('../views/home/helpcenter/index.vue'),
        meta: { name: 'help:center', icon: 'el-icon-s-opportunity', showName: 'home.homeLeft.helpCente', showHiden: true, index: '/helpcenter' }, // 表示该路由需要身份验证
        children: [
            {
                path: '/helpcenter/help', // 帮助文档
                name: 'help',
                component: () => import('../views/home/helpcenter/help.vue'),
                meta: { name: 'help:center:page', showName: 'home.homeLeft.helpCenteChildren.help', showHiden: true, index: '/helpcenter/help' } // 表示该路由需要身份验证
            },
            {
                path: '/helpcenter/createHelp', // 创建帮助文档
                name: 'createHelp',
                component: () => import('../views/home/helpcenter/createHelp.vue'),
                meta: { name: 'help:center:page', showHiden: false, index: '/helpcenter/help' }
            },
            {
                path: '/helpcenter/firstLevel', // 一级分类管理
                name: 'firstLevel',
                component: () => import('../views/home/helpcenter/firstLevel.vue'),
                meta: { name: 'help:center:category', showName: 'home.homeLeft.helpCenteChildren.firstLevel', showHiden: true, index: '/helpcenter/firstLevel' }
            },
            {
                path: '/helpcenter/firstLevel/add', // 添加级分类管理
                name: 'addFirstLevel',
                component: () => import('../views/home/helpcenter/addFirstLevel.vue'),
                meta: { name: 'help:center:category', showHiden: false, index: '/helpcenter/firstLevel' }
            },
            {
                path: '/helpcenter/secondary', // 二级分类管理
                name: 'secondary',
                component: () => import('../views/home/helpcenter/secondary.vue'),
                meta: { name: 'help:center:secondary:category', showName: 'home.homeLeft.helpCenteChildren.secondary', showHiden: true, index: '/helpcenter/secondary' }
            },
            {
                path: '/helpcenter/addSecondary', // 添加二级分类管理
                name: 'addSecondary',
                component: () => import('../views/home/helpcenter/addSecondary.vue'),
                meta: { name: 'help:center:secondary:category', showHiden: false, index: '/helpcenter/secondary' }
            },
            {
                path: '/helpcenter/hot', // 热门问题管理
                name: 'hot',
                component: () => import('../views/home/helpcenter/hot.vue'),
                meta: { name: 'help:center:hot:category', showName: 'home.homeLeft.helpCenteChildren.hot', showHiden: true, index: '/helpcenter/hot' }
            },
            {
                path: '/helpcenter/addHot', // 添加热门问题管理
                name: 'addHot',
                component: () => import('../views/home/helpcenter/addHot.vue'),
                meta: { name: 'help:center:hot:category', showHiden: false, index: '/helpcenter/hot' }
            }
        ]
    },
    {
        path: '/feedback', // 意见反馈 
        name: 'feedback',
        component: () => import('../views/home/feedback/index.vue'),
        meta: { name: 'feedback', icon: 'el-icon-s-comment', showName: 'home.homeLeft.feedback', showHiden: true, index: '/feedback' } // 表示该路由需要身份验证
    },
    {
        path: '/feedbackDetail', // 意见反馈 详情
        name: 'feedbackDetail',
        component: () => import('../views/home/feedback/detail.vue'),
        meta: { name: 'feedback', showHiden: false, index: '/feedback' } // 表示该路由需要身份验证
    },
    {
        path: '/systemsettings', // 系统设置 
        name: 'systemsettings',
        component: () => import('../views/home/systemsettings/index.vue'),
        meta: { name: 'system:setting', icon: 'el-icon-s-tools', showName: 'home.homeLeft.system', showHiden: true }, // 表示该路由需要身份验证
        children: [
            {
                path: '/systemsettings/createDictionary', // 创建字典管理
                name: 'createDictionary',
                component: () => import('../views/home/systemsettings/createDictionary.vue'),
                meta: { name: 'system:setting:dictionary', showHiden: false, index: '/systemsettings/dictionary' },
            },
            {
                path: '/systemsettings/dictionary', // 字典管理
                name: 'dictionary',
                component: () => import('../views/home/systemsettings/dictionary.vue'),
                meta: { name: 'system:setting:dictionary', showName: 'home.homeLeft.systemChildren.dictionary', showHiden: true, index: '/systemsettings/dictionary' },
            },
            {
                path: '/systemsettings/organization', // 组织管理
                name: 'organization',
                component: () => import('../views/home/systemsettings/organization.vue'),
                meta: { name: 'system:setting:organizational', showName: 'home.homeLeft.systemChildren.organization', showHiden: true, index: '/systemsettings/organization' },
            },
            {
                path: '/systemsettings/createOrganization', // 创建组织管理
                name: 'createOrganization',
                component: () => import('../views/home/systemsettings/createOrganization.vue'),
                meta: { name: 'system:setting:organizational', showHiden: false, index: '/systemsettings/organization' },
            },

            {
                path: '/systemsettings/department', // 部门管理
                name: 'department',
                component: () => import('../views/home/systemsettings/department.vue'),
                meta: { name: 'system:setting:depandstaff', showName: 'home.homeLeft.systemChildren.department', showHiden: true, index: '/systemsettings/department' },// 表示该路由需要身份验证
            },
            {
                path: '/systemsettings/createDepartment', // 部门添加
                name: 'createDepartment',
                component: () => import('../views/home/systemsettings/createDepartment.vue'),
                meta: { name: 'system:setting:depandstaff', showHiden: false, index: '/systemsettings/createDepartment' },
            },
            {
                path: '/systemsettings/roles', // 角色管理
                name: 'roles',
                component: () => import('../views/home/systemsettings/roles.vue'),
                meta: { name: 'system:setting:role', showName: 'home.homeLeft.systemChildren.roles', showHiden: true, index: '/systemsettings/roles' },
            },
            {
                path: '/systemsettings/createRoles', // 创建角色
                name: 'createRoles',
                component: () => import('../views/home/systemsettings/createRoles.vue'),
                meta: { name: 'system:setting:role', showHiden: false, index: '/systemsettings/roles' },
            },
           
            {
                path: '/systemsettings/staffAdd', // 员工添加
                name: 'staffAdd',
                component: () => import('../views/home/systemsettings/staffAdd.vue'),
                meta: { name: 'system:setting:depandstaff', showHiden: false, index: '/systemsettings/staff' },
            },
            {
                path: '/systemsettings/staffDetails', // 员工详情
                name: 'staffDetails',
                component: () => import('../views/home/systemsettings/staffDetails.vue'),
                meta: { name: 'system:setting:depandstaff', showHiden: false, index: '/systemsettings/staff' },
            }
        ]
    }
]


export default dynamicRoutes