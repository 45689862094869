import store from "@/store"

export default {
    LOGIN_IN(state, token) {
        state.UserToken = token;
    },
    LOGIN_OUT(state) {
        state.UserToken = '';
        state.userID = '';
        state.permissionList = ''

        store.dispatch("permission/CLEAR_RRR").then(() => {
           
        })
      
    },

    USER_ID(state, ids) {
        state.userID = ids;
    },

    PERMISSION_LIST(state, permiss) {
        state.permissionList = permiss;
    },

}