import axios from 'axios'
import { Message } from 'element-ui'
import { getToken, getMessage, removeToken, removeMessage } from '@/utils/auth'
import qs from 'qs'
import { Base64 } from 'js-base64'
import { Encrypt } from '@/utils/aes'
import { rsaEncrypt } from '@/utils/rsa'
import md5 from 'js-md5'
import store from "@/store"
import router from '../router'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000
})

// 请求拦截处理
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      //  如果有imageType说明是图片或文件格式，不做处理
      if (config.headers['imageType']) {
        if (getToken()) {
          config.headers['X-Access-Token'] = getToken()
        }
      } else {

        //json数据，加验签
        var oldData = {}
        if (config.data) {
          oldData = qs.parse(config.data)
        }
        if (getToken()) {
          //如果有userUuid,那么加到业务参数中
          oldData['userUuid'] = getToken()
        }
        // 获取当前时间戳
        var timestamp = new Date().getTime()
        // 生成AES Key
        var aesKey = Base64.encode('jupai' + timestamp)
        // 业务参数aes加密
        var reqContent = encodeURIComponent(Encrypt(JSON.stringify(oldData), aesKey))
        // md5签名
        var md5Sign = md5(reqContent).toUpperCase()
        // UrlDecode 解密公钥
        var rsaSign = encodeURIComponent(rsaEncrypt(aesKey))
        // 参数
        var params = {
          version: process.env.VUE_APP_VERSION,
          osType: process.env.VUE_APP_OSTYPE,
          reqContent: reqContent,
          md5Sign: md5Sign,
          rsaSign: rsaSign,
          timeStamp: timestamp,
          gps: 'gps',
        }
        config.data = params
        if (getToken()) {
          //如果有userUuid,那么加到业务参数中
          config.headers['X-Access-Token'] = getToken()
        }
        // 传递语言
        var language = localStorage.getItem('language')
        // config.headers['Biz-Lang'] = language ? language : 'en'
        config.headers['Biz-Lang'] = 'en'
        var timeZoneString = getMessage().timeZone;
        config.headers['Biz-Timezone'] = timeZoneString

      }

    }
    return config
  },
  error => {
    console.log(error, "4545454") // for debug
    return Promise.reject(error)
  }
)

// 响应拦截处理
service.interceptors.response.use(
  response => {
    // 200
    if (response.status == 200) {
      //  console.log('返回结果----', JSON.stringify(response.data));
      if (response.data && response.data.code == '401') {
        removeToken()
        removeMessage()
        store.commit('LOGIN_OUT')
        //退出账号
        router.replace('/login') // 重定向到登录页面 
      }
      return response.data
    } else {
      Message({ message: response.data.msg, type: 'error', duration: 2 * 1000 })
      return Promise.reject(response.data.msg)
    }

  },
  error => {
    // 500
    let { message } = error;
    if (message == "Network Error") {
      message = "Backend interface connection abnormality";

    } else if (message.includes("timeout")) {
      message = "System interface request timeout";
    } else if (message.includes("Request failed with status code")) {
      message = "System interface" + message.substr(message.length - 3) + "error";
    }
    // 设置退出程序
    if (error.response && error.response.status == 401) {
      removeToken()
      removeMessage()
      store.commit('LOGIN_OUT')
      //退出账号
      router.replace('/login') // 重定向到登录页面  
    }

    Message({ message: message, type: 'error', duration: 2 * 1000 })

    return Promise.reject(error)
  }
)

export default service