import router from "./index"
import store from "../store/index"

router.beforeEach((to, from, next) => {
    if (!store.state.UserToken) {
        // 未登录
        if (to.matched.length > 0 && !to.matched.some(record => record.meta.requiresAuth)) {
            next()
        } else {
            next({
                path: "/login"
            })
        }
    } else {
        // next()
        console.log('登录成功一户111111----', store.state.permission.permissionList);
        // store.state.permissionList
        // 用户已经登录  路由的访问权限 
        if (!store.state.permission.permissionList) {
            store.dispatch("permission/FETCH_PERMISSION").then(() => {
                // next(to)
                next({ ...to, replace: true })
            })
        } else {
            // store存在权限
            if (to.path !== "/login") {
                next();
            } else {
                next(from.fullPath)
            }
            // next();
        }
    }
})