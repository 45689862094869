import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from "@/utils/auth";

Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     redirect: '/login',
//     meta: { requiresAuth: false } // 表示该路由不需要身份验证
//   },
//   {
//     path: '/login', // 登录页
//     name: 'login',
//     component: () => import('../views/login/index.vue'),
//     meta: { requiresAuth: false } // 表示该路由不需要身份验证
//   },
//   {
//     path: '/home',
//     name: 'home',
//     redirect: '/index', // 重定向到首页
//     component: () => import('../views/home/main.vue'),
//     meta: { requiresAuth: true, index: '/index' }, // 表示该路由需要身份验证
//     children: [
//       {
//         path: '/index', // 首页
//         name: 'index',
//         component: () => import('../views/home/index/index.vue'),
//         meta: { requiresAuth: true, index: '/index' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/customer', // 客户管理 
//         name: 'customer',
//         component: () => import('../views/home/customer/index.vue'),
//         meta: { requiresAuth: true, index: '/customer' } // 表示该路由需要身份验证

//       },
//       {
//         path: '/customerDetail', // 客户管理-详情
//         name: 'customerDetail',
//         component: () => import('../views/home/customer/detail.vue'),
//         meta: { requiresAuth: true, index: '/customer' } // 表示该路由需要身份验证

//       },
//       {
//         path: '/exhibition', // 展会 
//         name: 'exhibition',
//         component: () => import('../views/home/exhibition/index.vue'),
//         meta: { requiresAuth: true, index: '/exhibition' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/exhibitionAdd', // 展会-创建
//         name: 'exhibitionAdd',
//         component: () => import('../views/home/exhibition/add.vue'),
//         meta: { requiresAuth: true, index: '/exhibition' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/exhibitionDetail', // 展会-创建
//         name: 'exhibitionDetail',
//         component: () => import('../views/home/exhibition/detail.vue'),
//         meta: { requiresAuth: true, index: '/exhibition' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/news', // 新闻
//         name: 'news',
//         component: () => import('../views/home/news/index.vue'),
//         meta: { requiresAuth: true, index: '/news' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/newsAdd', // 新闻-添加
//         name: 'newsAdd',
//         component: () => import('../views/home/news/add.vue'),
//         meta: { requiresAuth: true, index: '/news' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/newsDetail', // 新闻-详情
//         name: 'newsDetail',
//         component: () => import('../views/home/news/detail.vue'),
//         meta: { requiresAuth: true, index: '/news' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/recovery', // 回收信息管理 
//         name: 'recovery',
//         component: () => import('../views/home/recovery/index.vue'),
//         meta: { requiresAuth: true, index: '/recovery' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/recoveryDetail', // 回收信息管理详情
//         name: 'recoveryDetail',
//         component: () => import('../views/home/recovery/detail.vue'),
//         meta: { requiresAuth: true, index: '/recovery' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/saleproduct', // 处置信息管理 
//         name: 'saleproduct',
//         component: () => import('../views/home/saleproduct/index.vue'),
//         meta: { requiresAuth: true, index: '/saleproduct' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/saleproductDetail', // 处置信息管理 -详情
//         name: 'saleproductDetail',
//         component: () => import('../views/home/saleproduct/detail.vue'),
//         meta: { requiresAuth: true, index: '/saleproduct' } // 表示该路由需要身份验证

//       },
//       {
//         path: '/helpcenter', // 帮助中心 
//         name: 'helpcenter',
//         component: () => import('../views/home/helpcenter/index.vue'),
//         meta: { requiresAuth: true, index: '/helpcenter' }, // 表示该路由需要身份验证
//         children: [
//           {
//             path: '/helpcenter/help', // 帮助文档
//             name: 'help',
//             component: () => import('../views/home/helpcenter/help.vue'),
//             meta: { requiresAuth: true, index: '/helpcenter/help' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/helpcenter/createHelp', // 创建帮助文档
//             name: 'createHelp',
//             component: () => import('../views/home/helpcenter/createHelp.vue'),
//             meta: { requiresAuth: true, index: '/helpcenter/help' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/helpcenter/firstLevel', // 一级分类管理
//             name: 'firstLevel',
//             component: () => import('../views/home/helpcenter/firstLevel.vue'),
//             meta: { requiresAuth: true, index: '/helpcenter/firstLevel' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/helpcenter/firstLevel/add', // 一级分类管理
//             name: 'addFirstLevel',
//             component: () => import('../views/home/helpcenter/addFirstLevel.vue'),
//             meta: { requiresAuth: true, index: '/helpcenter/firstLevel' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/helpcenter/secondary', // 二级分类管理
//             name: 'secondary',
//             component: () => import('../views/home/helpcenter/secondary.vue'),
//             meta: { requiresAuth: true, index: '/helpcenter/secondary' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/helpcenter/addSecondary', // 二级分类管理
//             name: 'addSecondary',
//             component: () => import('../views/home/helpcenter/addSecondary.vue'),
//             meta: { requiresAuth: true, index: '/helpcenter/secondary' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/helpcenter/hot', // 热门问题管理
//             name: 'hot',
//             component: () => import('../views/home/helpcenter/hot.vue'),
//             meta: { requiresAuth: true, index: '/helpcenter/hot' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/helpcenter/addHot', // 热门问题管理
//             name: 'addHot',
//             component: () => import('../views/home/helpcenter/addHot.vue'),
//             meta: { requiresAuth: true, index: '/helpcenter/hot' } // 表示该路由需要身份验证
//           }
//         ]
//       },
//       {
//         path: '/feedback', // 意见反馈 
//         name: 'feedback',
//         component: () => import('../views/home/feedback/index.vue'),
//         meta: { requiresAuth: true, index: '/feedback' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/feedbackDetail', // 意见反馈 详情
//         name: 'feedbackDetail',
//         component: () => import('../views/home/feedback/detail.vue'),
//         meta: { requiresAuth: true, index: '/feedback' } // 表示该路由需要身份验证
//       },
//       {
//         path: '/systemsettings', // 系统设置 
//         name: 'systemsettings',
//         component: () => import('../views/home/systemsettings/index.vue'),
//         meta: { requiresAuth: true, }, // 表示该路由需要身份验证
//         children: [
//           {
//             path: '/systemsettings/createDictionary', // 创建字典管理
//             name: 'createDictionary',
//             component: () => import('../views/home/systemsettings/createDictionary.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/dictionary' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/systemsettings/dictionary', // 字典管理
//             name: 'dictionary',
//             component: () => import('../views/home/systemsettings/dictionary.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/dictionary' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/systemsettings/organization', // 组织管理
//             name: 'organization',
//             component: () => import('../views/home/systemsettings/organization.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/organization' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/systemsettings/createOrganization', // 创建组织管理
//             name: 'createOrganization',
//             component: () => import('../views/home/systemsettings/createOrganization.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/organization' } // 表示该路由需要身份验证
//           },

//           {
//             path: '/systemsettings/department', // 部门管理
//             name: 'department',
//             component: () => import('../views/home/systemsettings/department.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/department' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/systemsettings/createDepartment', // 部门添加
//             name: 'createDepartment',
//             component: () => import('../views/home/systemsettings/createDepartment.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/createDepartment' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/systemsettings/roles', // 角色管理
//             name: 'roles',
//             component: () => import('../views/home/systemsettings/roles.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/roles' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/systemsettings/createRoles', // 创建角色
//             name: 'createRoles',
//             component: () => import('../views/home/systemsettings/createRoles.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/roles' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/systemsettings/staff', // 员工管理
//             name: 'staff',
//             component: () => import('../views/home/systemsettings/staff.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/staff' } // 表示该路由需要身份验证
//           },
//           {
//             path: '/systemsettings/staffAdd', // 员工添加
//             name: 'staffAdd',
//             component: () => import('../views/home/systemsettings/staffAdd.vue'),
//             meta: { requiresAuth: true, index: '/systemsettings/staffAdd' } // 表示该路由需要身份验证
//           }
//         ]
//       }

//     ]
//   },
//   {
//     path: '*',
//     name: '404',
//     component: () => import('../views/home/404.vue')
//   }
// ]




// 初始化路由
const routes = [

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
  },

]

// 动态路由
export const DynamicRoutes = [
  {
    path: '',
    component: () => import('../views/home/main.vue'),
    name: 'container',
    meat: {
      requiresAuth: true,
      name: 'index'
    },
    children: [
      {
        path: "/index",
        component: () => import('../views/home/index/index.vue'),
        name: 'index',
        meta: { name: 'index', icon: 'el-icon-s-home', showName: 'home.homeLeft.index', showHiden: true, index: '/index' }
      },

    ],

  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/home/404.vue')
  },

]



const router = new VueRouter({
  mode: 'history',
  routes
});
// router.beforeEach((to, from, next) => {
//   // 检查目标路由是否需要身份验证  
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // 如果目标路由需要身份验证，检查用户是否已登录  
//     // 这里可以根据你的实际需求来判断用户是否已登录，例如从localStorage中获取用户信息等  
//     var token = getToken();
//     if (!token) { // 假设isUserLoggedIn是一个用于判断用户是否已登录的函数  
//       // 用户未登录，重定向到登录页面  
//       next(routes[0]); // 使用name作为路由名进行重定向  
//     } else {
//       next(); // 用户已登录，继续导航到目标路由  
//     }
//   } else {
//     next(); // 目标路由不需要身份验证，继续导航到目标路由  
//   }
// });
export default router
