import Vue from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'

// 基础css样式
import './assets/css/base.css';
import './router/permission'


// ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import i18n from './views/i18n';

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
// Vue.prototype.$moment = moment
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
