import request from '@/utils/request'
// 登录接口
export function login(data) {
  return request({
    url: 'adminUser/login',
    method: 'post',
    data
  })
}

// 获取权限
export function permissions(data) {
  return request({
    url: '/adminUser/permissionsForLoggedInUsers',
    method: 'post',
    data
  })
}



// export function getInfo(token) {
//   return request({
//     url: '/vue-element-admin/user/info',
//     method: 'get',
//     params: { token }
//   })
// }

// export function logout() {
//   return request({
//     url: '/vue-element-admin/user/logout',
//     method: 'post'
//   })
// }
