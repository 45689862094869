// 英文
export default {
    // 登录界面
    login: {
        title: 'Feijiu Management Platform',
        loginTitle: 'Account login',
        loginAccount: 'Enter Account',
        loginPassword: 'Enter Password',
        loginBtn: 'Sign In',
        msg1: 'please enter user name',
        msg2: 'please enter password',
        msg3: 'Length 0 to 8 characters',
    },
    errorPage: {
        message: 'The webmaster said that you can not enter this page...',
        message1: 'Please check that the URL you entered is correct, or click the button below to return to the homepage.',
        btnName: 'Back to home',
    },
    // 首页
    home: {
        // 左侧
        homeLeft: {
            index: 'Home',
            customer: 'Customer',
            exhibition: 'Exhibition',
            news: 'News',
            recovery: 'Recovery',
            saleProduct: 'Sale Product',
            helpCente: 'Help Center',
            feedback: 'Feedback',
            system: 'System Setting',
            systemChildren: {
                dictionary: 'dictionary',
                organization: 'organization',
                department: 'Department & staff',
                roles: 'roles',
                staff: 'staff'
            },
            helpCenteChildren: {
                help: 'Help document',
                firstLevel: 'Primary classification',
                secondary: 'secondary classification',
                hot: 'hot topics',
            }
        },
        // 顶部
        homeTop: {
            leftTitle: 'Feijiu Management Platform'
        },
        homeRight: {
            item0: 'Personal Information',
            item1: 'Change password',
            item2: 'Time zone preference',
            item3: 'Exit Account',
        },
        msg: 'There is currently no data available',
        btnName: 'to-do-list',
    },
    // 客户管理
    customer: {
        time: 'Registration time',
        method: 'Registration method',
        name: 'name',
        phone: 'phone number',
        email: 'Email',
        source: 'Source channel',
        area: 'area',
        member: 'member',
        operate: 'operate',
        detailBtn: 'detail',
        detail: {
            title: 'Basic information',
            titleJD: 'Thawing',
            titleDJ: 'Freeze',
            titleJH: 'To be activated',
            msg1: 'This account has been unfrozen',
            msg2: 'This account has been frozen',
            msg3: 'The current status is:',
            msg4: 'This operation will freeze the user account. Do you confirm the operation ?',
            msg5: 'This operation will unfreeze the user account. Do you confirm the operation ?',
            btn1: 'Sure',
            btn2: 'Cancel',
        }
    },
    // 公共信息validate.js
    validate: {
        // 注册来源
        registSource: {
            namepc: 'PC Registration',
            nameand: 'Android registration',
            nameios: 'IOS registration',
            namem: 'M registration',
            nameo: 'Other',
        },
        // 注册方式
        registMethod: {
            namee: 'Email registration',
            namep: 'Phone registration',
            nameo: 'Other',
        },
        // 顶部搜索
        searchTop: {
            search: 'search',
            cz: 'reset'
        },
        back: 'Back',
        defaultInput: 'Please enter ',
        defaultSelect: 'Please choose ',
        startTime: 'Start date',
        between: 'to',
        endTime: 'End date',
        success: 'Success!',
        addSuccess: 'Add Success!',
        updateSuccess: 'Update Success!',
        discussPersonally: 'Negotiable'
    },
    //展会信息
    exhibition: {
        add: {
            title: 'Create Exhibition',
            titleEdit: 'Edit Exhibition',
            keyword: 'Keyword',
            exhibitionName: 'Exhibition name',
            category: 'Category',
            type: 'Type',
            aera: 'Aera',
            organizer: 'Organizer',
            exhibitionSite: 'Exhibition site',
            holdingTime: 'Holding time',
            eventCover: 'Event Cover',
            exhibitionType1: 'maintenance',
            exhibitionType2: 'Third party links',
            exhibitionDetails: 'exhibitionDetails',
            button1: 'Cancel',
            button2: 'Submit for review',
            button3: 'Save Draft',
            placer1: 'Please enter the exhibition name',
            placer2: 'Please enter the exhibition venue',
            placer3: 'Please enter the exhibition organizer',
            placer4: 'Please enter a third-party link',
            placer5: 'Please Enter category',
            placer6: 'Please Choose area',
            placer7: 'Please Choose Holding time',
            placer8: 'Please upload a cover image',
            placer9: 'Please select one',
            placer10: 'Suggested size: 1440*303px',
            placer11: 'Multiple keywords usage "/" segmentation',
        },
        detail: {
            title: 'Exhibition Information',
            exhibitionName: 'Exhibition name',
            type: 'Category',
            aera: 'Aera',
            organizer: 'Organizer',
            exhibitionSite: 'Exhibition site',
            holdingTime: 'Holding time',
            eventCover: 'Event Cover',
            exhibitionType1: 'maintenance',
            exhibitionType2: 'Third party links',
            operationRecords: 'Operation records',
            content: 'Content',
            operationRecordsList: {
                index: 'Serial Number',
                adminUserName: 'operator',
                createTime: 'operation time',
                operateType: 'operate',
                mark: 'notes',
            },
            mess: 'Success!',
            btn1: 'Removed from shelves',
            btn2: 'Reason for delisting',
            btn3: 'Cancel',
            btn4: 'Sure',
            btn5: 'Approved',
            btn6: 'Is this data approved?',
            btn7: 'Shelf review failed',
            btn8: 'Reason for failure',
            btn9: 'Submit',
            btn10: 'Withdraw',
            btn11: 'Withdraw this data?',
            btn12: 'On the shelves',
            btn13: 'List this data?',
            btn14: 'Delete',
            btn15: 'Delete this data?',

        },
        list: {
            exhibitionName: 'Exhibition name',
            exhibitionState: 'state',
            exhibitionCountry: 'aera',
            organizer: 'organizer',
            holdTime: 'Holding time',
            adminUserName: 'Creator',
            createTime: 'Create time',
        },
        // 展会状态
        exhibitionState: {
            name0: 'Draft',
            name1: 'Audit',
            name2: 'To begin',
            name3: 'In progress',
            name4: 'Ended',
            name5: 'Removed',
            name6: 'Deleted',
        }
    },
    // 帮助中心
    help: {
        // 状态
        helpState: {
            name0: 'Deactivate',
            name1: 'Enable'
        },
        //适用于
        documentScope: {
            name0: 'PC',
            name1: 'mobile terminal',
            name2: 'PC And Mobile terminal',
            name3: 'Mobile And PC terminal',
        },
        // 帮助文档列表
        webHelpList: {
            titleName: 'Create document',
            title: 'Title',
            status: 'state',
            className: 'Category',
            hotName: 'Hot Topics',
            effectiveQuantity: 'Effective feedback',
            invalidQuantity: 'Feedback invalid',
            sort: 'sort',
            documentScope: 'refer to',
            updateTime: 'Create Time',
            firstClassName: 'First level classification',
            secondaryName: 'Secondary classification',
            detailName: 'Document Details',
            btn0: 'Enable',
            btn1: 'Deactivate',
            btn2: 'Save',
            btn3: 'Cancel',
            btn4: 'Delete',
            btn5: 'Removed from shelves',
            btn6: 'Reason for delisting',
            btn7: 'Cancel',
            btn8: 'Sure',
            msg0: 'Please enter a document title',
            msg1: '0 to 200 characters in length',
            msg2: 'Please enter sorting',
            msg3: 'Please enter article details',
            msg4: 'Please select applicable (end)',
            msg5: 'Please select a first-level category',
            msg6: 'Please select a status',
            msg7: 'Added successfully',
            msg8: 'Successfully modified',
            msg9: 'successfully deleted',
            msg10: 'Please enter a positive integer.',
            msg11: 'Please select a secondary category',
        },
        // 分类列表
        categoryList: {
            status: 'state',
            documentScope: 'refer to',
            title: 'title',
            sort: 'sort',
            updateTime: 'Create Time',
            primaryClassification: 'Primary classification',
            document: 'document',
            basicInfo: 'Basic information',
            msg0: 'Please enter the category name',
            msg1: 'Please select system type',
            msg2: 'Please select a status',
            msg3: 'Please select a first-level category',
            msg4: 'This operation will be permanently deleted, do you want to continue?',
            msg5: 'update completed!',
        },
    },
    // 操作按钮
    option: {
        operate: 'operate',
        add: 'Create',
        detail: 'details',
        edit: 'edit',
        SubmitForReview: 'Submit review',
        ReviewRejection: 'Review rejected',
        Approved: 'Examination passed',
        WithdrawToDraft: 'Withdraw to draft',
        OffShelf: 'Removed from shelves',
        UpForReview: 'Up for review',
        delete: 'Delete',
        cancel: 'Cancel',
        save: 'Save'
    },
    // 新闻信息
    news: {
        // 新闻状态
        name0: 'Draft',
        name1: 'Audit',
        name2: 'On the shelves',
        name3: 'Removed',
        title: 'News Title',
        state: 'State',
        category: 'Category',
        area: 'Area',
        sources: 'News sources',
        creator: 'Creator',
        time: 'Create Time',
        operate: 'Operate',
        btnName: 'detail',
        addBtn: 'Create',
        newDetail: 'News Details',
        detailTitle: 'News Information',
        imageCover: 'Event Cover',
        newsSummary: 'News Summary',
        addNew: 'Create News',
        editNew: 'Edit News',
        searchDic: {
            title: 'News Title',
            placeTitle: 'Please enter the news title',
            state: 'State',
            placeState: 'Please select a status',
            category: 'Category',
            placeCategory: 'please select a category',
            area: 'Area',
            placeArea: 'please select a area',
            placeSouce: 'Please enter news sources',
        },
        detail: {
            btn1: 'Cancle',
            btn2: 'Sure',
            btn3: 'Off shelf',
            btn4: 'Reason for delisting',
            btn5: 'Listing review failed',// Listing review failed
            btn6: 'Reason for failure:', // Reason for failure:
            btn7: 'Please enter the reason for rejection', // 
            btn8: 'Examination passed',
            btn9: 'Are you sure you want to pass the review?',
            btn10: 'Off shelf', // Off shelf
            btn11: 'Reason for delisting:', // Reason for delisting:
            btn12: 'Please enter the reason for delisting',
            btn13: 'On shelf',
            btn14: 'Are you sure you want to put this information on the shelves?',
            btn15: 'Delete', // delete
            btn16: 'Delete this data?', // Delete this data?
        },
        add: {
            placer1: 'Please enter the news title',
            placer2: '10 to 500 characters in length',
            placer3: 'Please select a category',
            placer4: 'Please select a area',
            placer5: 'Please select a news source',
            placer6: 'Please fill in the news summary',
            placer7: 'Please fill in the news content',
            placer8: '',
            placer9: '',
            placer10: '',
            placer11: '',
            placer12: '',
            placer13: '',
            placer14: '',
        }
    },
    dictionarys: {
        name0: 'Dictionary name',
        name1: 'Dictionary encoding',
        name2: 'Dictionary value',
        name3: 'State',
        name4: 'Update time',
        name5: 'Operate',
        name6: 'details',
        state: 'State',
        hide: 'Deactivate',
        show: 'Enable',
        createName: 'Create dictionary',
        maintain: 'maintain',
        sort: 'Sort',
        delete_alert_msg: 'This operation will permanently delete this data, do you want to continue?',
        alert_btn_name0: 'Sure',
        alert_btn_name1: 'Cancel',
        delete_msg: 'successfully deleted',
        save_msg0: 'Please provide complete parameters',
        save_msg1: 'Saved successfully',
        create_msg: 'Created successfully',
        input_message0: 'Please enter the dictionary name',
        input_message1: 'Please enter the dictionary code',
        input_message2: 'Please enter the dictionary maintenance value',
    },
    saleproduct: {
        infoState: {
            all: 'All',
            pendingReview: 'Pending shelving review',
            auditFailure: 'Listing review failed',
            passed: 'Listed',
            removed: 'Delisted'
        },
        title0: 'Title',
        title1: 'Category',
        title2: 'state',
        title3: 'Location',
        title4: 'Available Regions',
        title5: 'Delivery Method',
        title6: 'Publisher',
        title7: 'Member',
        title8: 'Publish time',
        title9: 'Operate',
        unknown: 'unknown',
        state0: 'Draft',
        state1: 'Audit',
        state2: 'On the shelves',
        state3: 'Removed',
        state4: 'Audit failed',
        memberState1: 'No',
        memberState2: 'Yes',
        deliveryMethod1: 'self-pickup',
        deliveryMethod2: 'Delivery',
    },
    saleproductDetail: {
        baseInfo: 'Basic information',
        title0: 'Title',
        title1: 'Categories',
        title2: 'Disposal quantity',
        title3: 'Selling price',
        title4: 'Detailed introduction',
        title5: 'Product images',
        title6: 'Location',
        title7: 'Available Regions',
        title8: 'Delivery Method',
        title9: 'name',
        title10: 'phone number',
        title11: 'Email',
        title12: 'aera',
        title13: 'Registration method',
        title14: 'Registration time',
        title15: 'Source channel',
        title16: 'member',
        title17: 'Serial Number',
        title18: 'operator',
        title19: 'operation time',
        title20: 'operate',
        title21: 'notes',
        title22: 'Approved',
        title23: 'Reject',
        title24: 'Off shelf',
        releaseInfo: 'Publisher Information',
        operationRecords: 'Operation records',
        alert_msg1: 'You confirm that you want to review and approve this message?',
        alert_msg2: 'Please enter the reason for rejection',
        alert_msg3: 'Please enter the reason for delisting',
        btn1: 'Sure',
        btn2: 'Cancel',
        member: 'Y',
        membernon: 'N',
    },
    recovery: {
        purchaseLocation: 'Purchase Location',
        title2: 'Sourcing quantity',
        title3: 'Eepected price',
        title4: 'Detailed requirements',
        title7: 'Purchase Location',
    },
    feedback: {
        name0: 'Feedback Summary',
        name1: 'state',
        name2: 'Feedback person',
        name3: 'Feedback time',
        name4: 'operate',
        name5: 'Label',
        name6: 'Area',
        name7: 'Feedback person',
        name8: 'phone number',
        name9: 'Email',
        name11: 'Member',
        name12: 'Feedback time',
        name13: 'Feedback',
        state0: 'Not processed',
        state1: 'Processed',
        unknown: 'unknown',
        mark: 'mark',
        reply: 'reply',
        btn0: 'Cancel',
        btn1: 'Sure',
        btn2: 'Non-members',
        btn3: 'Member',
        alert_msg1: 'Please select a label?',
        alert_msg2: 'Please enter the reply content?',
        alert_msg3: 'Reply successful',
        feedBack: {
            feedBack: 'Feedback',
            title: 'How do you feel about Feijiu.com ? (Choose only one answer)',
            satisfied: 'Satisfied',
            satisTitle: 'You are satisfied because of:(Choose only one answer)',
            satis1: 'There is a lot of information about business opportunities',
            satis2: 'Easy and smooth to use',
            satis3: 'Brought benefits to me',
            satis4: 'Other',

            unsatisfied: 'Unsatisfied',
            unsatisfiedTitle: 'You are unsatisfied because of:(Choose only one answer)',//不满意的标题 待补充
            unSatis1: 'Inconvenient to use',
            unSatis2: 'Too few features',
            unSatis3: 'Unhelpful solution',
            unSatis4: 'The service is not satisfied',
            cue: 'If you have any other suggestions/complaints about our Feijiu.com, please do not hesitate to let us know. Thank you very much for your valuable information.'

        },
        input_placeholder: 'Please enter the reply content'
    },
    changepassword: {
        title: 'Change password',
        name0: 'Old password',
        name1: 'New Password',
        name2: 'Re-enter New password',
        btn0: 'Save',
        btn1: 'Cancel',
        msg0: 'Please enter old password',
        msg1: 'Please enter a new password',
        msg2: 'Please enter password again',
        msg3: 'The password entered twice is inconsistent!',
        msg4: 'Successfully modified',
    },
    personalData: {
        title: 'Personal Information',
        name0: 'Account',
        name1: 'Name',
        name2: 'Phone number',
        name3: 'Email',
        btn0: 'Save',
        btn1: 'Cancel',
        msg0: 'Please type in your name',
        msg1: 'Please enter phone number',
        msg2: 'Malformed phone number',
        msg3: 'Please input the email address',
        msg4: 'Saved successfully',
    },
    timeZoneData: {
        title: 'Time zone preference',
        name0: 'The system display time will be displayed according to the selected time zone',
        btn0: 'Save',
        btn1: 'Cancel',
        msg0: 'Please select a time zone',
        msg1: 'Saved successfully',
    },

}
