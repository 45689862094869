
// 中文配置
import { webHelpListData } from "@/api/help";

export default {
    // 登录界面
    login: {
        title: 'Feijiu管理平台',
        loginTitle: '账号登录',
        loginAccount: '请输入账号',
        loginPassword: '请输入密码',
        loginBtn: '登录',
        msg1: '请输入用户名',
        msg2: '请输入密码',
        msg3: '长度在 0 到 8 个字符',
        loginBtn: '登录',
    },
    errorPage: {
        message: '站长说您无法进入此页面...',
        message1: '请检查您输入的网址是否正确，或点击下面的按钮返回首页。',
        btnName: '返回首页',
    },
    // 首页
    home: {
        // 左侧
        homeLeft: {
            index: '首页',
            customer: '客户管理',
            exhibition: '展会',
            news: '新闻',
            recovery: '回收信息管理',
            saleProduct: '处置信息管理',
            helpCente: '帮助中心',
            feedback: '意见反馈',
            system: '系统设置',
            systemChildren: {
                dictionary: '字典管理',
                organization: '组织管理',
                department: '部门管理',
                roles: '角色管理',
                staff: '员工管理'
            },
            helpCenteChildren: {
                help: '帮助文档',
                firstLevel: '一级分类管理',
                secondary: '二级分类管理',
                hot: '热门问题',
            }
        },
        // 顶部
        homeTop: {
            leftTitle: '废旧网国际站管理后台'
        },
        homeRight: {
            item0: '个人信息',
            item1: '修改密码',
            item2: '时区设置',
            item3: '退出账号',
        },
        msg: '目前没有可用数据',
        btnName: '待办事项列表',
    },
    // 客户管理
    customer: {
        time: '注册时间',
        method: '注册方式',
        name: '名字',
        phone: '手机号码',
        email: '邮箱',
        source: '来源渠道',
        area: '地区',
        member: '会员',
        operate: '操作',
        detailBtn: '详情',
        detail: {
            title: '基本信息',
            titleJD: '解冻',
            titleDJ: '冻结',
            titleJH: '待激活',
            msg1: '该账号已解冻',
            msg2: '该账号已冻结',
            msg3: '当前的状态是:',
            msg4: '此操作将会冻结该用户账号，是否确认操作?',
            msg5: '此操作将会解冻该用户账号，是否确认操作?',
            btn1: '确定',
            btn2: '取消',
        }
    },
    // 公共信息validate.js
    validate: {
        // 注册来源
        registSource: {
            namepc: 'PC注册',
            nameand: 'APP-Android注册',
            nameios: 'APP-IOS注册',
            namem: 'M站注册',
            nameo: '其他',
        },
        // 注册方式
        registMethod: {
            namee: '邮箱注册',
            namep: '手机号注册',
            nameo: '其他',
        },
        // 顶部搜索
        searchTop: {
            search: '搜索',
            cz: '重置'
        },
        back: '返回',
        defaultInput: '请输入',
        defaultSelect: '请选择',
        startTime: '开始日期',
        between: '至',
        endTime: '结束日期',
        success: '操作成功!',
        addSuccess: '添加成功!',
        updateSuccess: '更新成功!',
        discussPersonally: '更新成功!',
    },
    //展会信息
    exhibition: {
        add: {
            title: '创建展会',
            titleEdit: '编辑展会',
            keyword: '关键词',
            exhibitionName: '展会名称',
            category: '分类',
            type: '类型',
            aera: '展会国家',
            organizer: '主办单位',
            exhibitionSite: '举办地点',
            holdingTime: '举办时间',
            eventCover: '封面',
            exhibitionType1: '我方维护',
            exhibitionType2: '第三方链接',
            exhibitionDetails: '展会详情',
            button1: '取消（返回）',
            button2: '提交审核',
            button3: '保存草稿',

            placer1: '请输入展会名称',
            placer2: '请输入展会举办地点',
            placer3: '请输入展会举办单位',
            placer4: '请输入第三方链接',
            placer5: '请选择分类',
            placer6: '请选择地区',
            placer7: '请选择举办时间',
            placer8: '请上传一张封面图片',
            placer9: '请选择一个',
            placer10: '建议尺寸: 1440*303px',
            placer11: '多个时以“/”分隔',
        },
        detail: {
            title: '展会详情',
            exhibitionName: '展会名称',
            type: '类型',
            aera: '展会国家',
            organizer: '主办单位',
            exhibitionSite: '举办地点',
            holdingTime: '举办时间',
            eventCover: '封面',
            exhibitionType1: '我方维护',
            exhibitionType2: '第三方链接',
            operationRecords: '操作记录',
            content: '详情',
            operationRecordsList: {
                index: '序号',
                adminUserName: '操作人员',
                createTime: '操作时间',
                operateType: '操作说明',
                mark: '备注',
            },
            mess: '操作成功!',
            btn1: '下架',
            btn2: '下架原因',
            btn3: '取消',
            btn4: '确定',
            btn5: '通过审核',
            btn6: '审核通过此数据?',
            btn7: '上架审核失败',
            btn8: '失败理由',
            btn9: '提交',
            btn10: '撤回',
            btn11: '撤回此数据?',
            btn12: '上架',
            btn13: '上架此数据?',
            btn14: '删除',
            btn15: '删除此数据？',
        },
        list: {
            exhibitionName: '展会名称',
            exhibitionState: '状态',
            exhibitionCountry: '展会国家',
            organizer: '主办单位',
            holdTime: '举办时间',
            adminUserName: '创建人',
            createTime: '创建时间',
        },
        // 展会状态
        exhibitionState: {
            name0: '草稿',
            name1: '待审核',
            name2: '待开始',
            name3: '进行中',
            name4: '已结束',
            name5: '已下架',
            name6: '已删除',
        }
    },
    // 帮助中心
    help: {
        // 状态
        helpState: {
            name0: '停用',
            name1: '启用'
        },
        //适用于
        documentScope: {
            name0: 'PC',
            name1: 'M站',
            name2: 'PC,M站',
            name3: 'M站, PC',
        },
        // 帮助文档列表
        webHelpList: {
            titleName: '创建帮助中心文档',
            title: '文档标题',
            status: '状态',
            className: '分类',
            hotName: '热门分类',
            effectiveQuantity: '有效反馈',
            invalidQuantity: '无效反馈',
            sort: '排序',
            documentScope: '适用于（端）',
            updateTime: '创建时间',
            firstClassName: '一级分类',
            secondaryName: '二级分类',
            detailName: '文章详情',
            btn0: '启用',
            btn1: '停用',
            btn2: '保存',
            btn3: '取消',
            btn4: '删除',
            btn5: '下架',
            btn6: '下架原因',
            btn7: '取消',
            btn8: '确定',
            msg0: '请输入文档标题',
            msg1: '长度在 0 到 200 个字符',
            msg2: '请输入排序',
            msg3: '请输入文章详情',
            msg4: '请选择适用（端）',
            msg5: '请选择一级分类',
            msg11: '请选择二级分类',
            msg6: '请选择状态',
            msg7: '添加成功',
            msg8: '修改成功',
            msg9: '删除成功',
            msg10: '请输入正整数',
        },
        // 分类列表
        categoryList: {
            status: '状态',
            documentScope: '适用于（端）',
            title: '分类名称',
            sort: '排序',
            updateTime: '创建时间',
            primaryClassification: '一级分类',
            document: '文档',
            basicInfo: '基本信息',
            msg0: '请输入分类名称',
            msg1: '请选择系统类型',
            msg2: '请选择状态',
            msg3: '请选择一级分类',
            msg4: '此操作将永久删除, 是否继续?',
            msg5: '更新成功!',
        },

    },
    // 操作按钮
    option: {
        operate: '操作',
        add: '创建',
        detail: '查看详情',
        edit: '编辑',
        SubmitForReview: '提交审核',
        ReviewRejection: '审核驳回',
        Approved: '审核通过',
        WithdrawToDraft: '撤回至草稿',
        OffShelf: '下架',
        UpForReview: '上架至审核',
        delete: '删除',
        cancel: '取消（返回）',
        save: '保存'
    },
    // 新闻信息
    news: {
        // 新闻状态
        name0: '草稿',
        name1: '待审核',
        name2: '已上架',
        name3: '已下架',
        // 列表数据
        title: '新闻标题',
        state: '状态',
        category: '分类',
        area: '地区',
        sources: '新闻来源',
        creator: '作者',
        time: '创建时间',
        operate: '操作',
        btnName: '详情',
        addBtn: '创建',
        newDetail: '新闻详情',
        detailTitle: '基本信息',
        imageCover: '封面图',
        newsSummary: '摘要',
        addNew: '创建新闻',
        editNew: '编辑新闻',
        // 搜索栏
        searchDic: {
            title: '新闻标题',
            placeTitle: '请填写新闻标题',
            state: '状态',
            placeState: '请选择状态',
            category: '分类',
            placeCategory: '请选择分类',
            area: '地区',
            placeArea: '请选择地区',
            placeSouce: '请填写新闻来源',
        },
        add: {
            placer1: '请输入新闻标题',
            placer2: '长度在 10 到 500 个字符',
            placer3: '请选择分类',
            placer4: '请选择地区',
            placer5: '请选择新闻来源',
            placer6: '请填写新闻摘要',
            placer7: '请填写新闻内容',
            placer8: '',
            placer9: '',
            placer10: '',
            placer11: '',
            placer12: '',
            placer13: '',
            placer14: '',
        },
        detail: {
            btn1: '取消',
            btn2: '确定',
            btn3: '下架',
            btn4: '下架原因',
            btn5: '审核失败',// Listing review failed
            btn6: '审核失败原因:', // Reason for failure:
            btn7: '请填写拒绝的原因', // 
            btn8: '审核通过',
            btn9: '您确定要通过审核吗?',
            btn10: '下架', // Off shelf
            btn11: '下架原因:', // Reason for delisting:
            btn12: '请填写下架原因',
            btn13: '上架',
            btn14: '您确定要将此信息上架吗?',
            btn15: '删除', // delete
            btn16: '删除这条信息?', // Delete this data?
        },

    },
    dictionarys: {
        name0: '字典名称',
        name1: '字典编码',
        name2: '字典值',
        name3: '状态',
        name4: '更新时间',
        name5: '操作',
        name6: '查看详情',
        state: '状态',
        hide: '隐藏',
        show: '显示',
        createName: '创建字典',
        maintain: '字典值维护',
        sort: '排序',
        delete_alert_msg: '此操作将永久删除该数据, 是否继续?',
        alert_btn_name0: '确定',
        alert_btn_name1: '取消',
        delete_msg: '删除成功',
        save_msg0: '请补充完整参数',
        save_msg1: '保存成功',
        create_msg: '创建成功',
        input_message0: '请输入字典名称',
        input_message1: '请输入字典编码',
        input_message2: '请输入字典维护值',
    },
    saleproduct: {
        infoState: {
            all: '全部',
            pendingReview: '待上架审核',
            auditFailure: '上架审核失败',
            passed: '已上架',
            removed: '已下架'
        },
        title0: '标题',
        title1: '分类',
        title2: '状态',
        title3: '所在地',
        title4: '可供应地区',
        title5: '配送方式',
        title6: '发布人',
        title7: '是否会员',
        title8: '发布时间',
        title9: '操作',
        unknown: '未知',
        state0: '草稿',
        state1: '待审核',
        state2: '已上架',
        state3: '已下架',
        state4: '审核失败',
        memberState1: '否',
        memberState2: '是',
        deliveryMethod1: '自取',
        deliveryMethod2: '快递',
    },
    saleproductDetail: {
        baseInfo: '基本信息',
        title0: '标题',
        title1: '分类',
        title2: '处置量',
        title3: '出售价格',
        title4: '详细介绍',
        title5: '产品照片',
        title6: '所在地',
        title7: '可供应地区',
        title8: '配送方式',
        title9: '姓名',
        title10: '电话',
        title11: '邮箱',
        title12: '地区',
        title13: '注册方式',
        title14: '注册时间',
        title15: '来源渠道',
        title16: '是否会员',
        title17: '序号',
        title18: '操作人',
        title19: '操作时间',
        title20: '操作项',
        title21: '备注',
        title22: '审核通过',
        title23: '驳回',
        title24: '下架',
        releaseInfo: '发布信息',
        operationRecords: '操作记录',
        alert_msg1: '您确认要审核通过这条信息',
        alert_msg2: '请输入驳回理由',
        alert_msg3: '请输入下架原因',
        btn1: '确定',
        btn2: '取消',
        member: '普通会员',
        membernon: '非会员',
    },
    recovery: {
        purchaseLocation: '求购地',
        title2: '采购数量',
        title3: '预计价格',
        title4: '详细要求',
        title7: '购买地点',
    },
    feedback: {
        name0: '反馈摘要',
        name1: '状态',
        name2: '反馈人',
        name3: '反馈时间',
        name4: '操作',
        name5: '标签',
        name6: '地区',
        name7: '姓名',
        name8: '手机号',
        name9: '邮箱',
        name11: '是否会员',
        name12: '反馈时间',
        name13: '反馈详情',
        state0: '未处理',
        state1: '已处理',
        unknown: '未知',
        mark: '标记',
        reply: '回复',
        btn0: '取 消',
        btn1: '确定',
        btn2: '非会员',
        btn3: '普通会员',
        alert_msg1: '请选择标签',
        alert_msg2: '请输入回复内容',
        alert_msg3: '回复成功',
        feedBack: {
            feedBack: '意见反馈',
            title: '您如何看待 Feijiu.com？（只选择一个答案）',
            satisfied: '满意',
            unsatisfied: '不满意',
            satisTitle: '您之所以满意，是因为：（只选择一个 答案）',
            satis1: '商机信息很多',
            satis2: '使用方便顺畅',
            satis3: '为我带来了收益',
            satis4: '其他',
            unsatisfiedTitle: '您之所以不满意，是因为：（只选择一个答案）',//不满意的标题 待补充
            unSatis1: '使用不方便',
            unSatis2: '无关的答案',
            unSatis3: '无益的解决方案',
            unSatis4: '服务不满意',
            cue: '如果您对我们Feijiu.com有任何其他建议/投诉，请随时通知我们。非常感谢您的宝贵信息。'
        },
        input_placeholder: '请输入回复内容'
    },
    changepassword: {
        title: '修改密码',
        name0: '旧密码',
        name1: '新密码',
        name2: '确认密码',
        btn0: '保存',
        btn1: '取消',
        msg0: '请输入旧密码',
        msg1: '请输入密码',
        msg2: '请再次输入密码',
        msg3: '两次输入密码不一致!',
        msg4: '修改成功',
    },
    changepassword: {
        title: '修改密码',
        name0: '旧密码',
        name1: '新密码',
        name2: '确认密码',
        btn0: '保存',
        btn1: '取消',
        msg0: '请输入旧密码',
        msg1: '请输入密码',
        msg2: '请再次输入密码',
        msg3: '两次输入密码不一致!',
        msg4: '修改成功',
    },
    personalData: {
        title: '个人信息',
        name0: '用户名',
        name1: '姓名',
        name2: '手机号码',
        name3: '邮箱地址',
        btn0: '保存',
        btn1: '取消',
        msg0: '请输入姓名',
        msg1: '请输入手机号',
        msg2: '手机号格式错误',
        msg3: '请输入邮箱地址',
        msg4: '保存成功',
    },
    timeZoneData: {
        title: '修改时区',
        name0: '系统展示的时间将会按照选择的时区展示',
        btn0: '保存',
        btn1: '取消',
        msg0: '请选择时区',
        msg1: '保存成功',
    }
}
